
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import QuestionTemp from "./components/question-temp.vue";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const learning = namespace("learning");
const base = namespace("base");
@Component({
  components: { FileUpload, QuestionTemp }
})
export default class TestItemEdit extends Vue {
  @learning.Action queryExamPaperDetail;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  @learning.Action revokeExam;
  @base.Action getDictionaryList;
  @learning.Mutation setQuestionTypeList;
  dayjs = dayjs;
  showRevokeWarning: boolean = false;
  get breadData() {
    return [
      { name: t("v210831.examination-certification"), path: "/learning/exam" },
      { name: t("v210831.examination-details") }
    ];
  }
  get examPaperTempCode() {
    return this.$route.params.id;
  }
  created() {
    this.init();
  }
  examPaperTempData: any = {};
  groupIndex: number = 1;
  init() {
    this.getDictionaryList("question_type").then(data => {
      this.setQuestionTypeList(data);
    });
    this.queryExamPaperDetail({
      examPaperTempCode: this.examPaperTempCode
    }).then(data => {
      let questionList = data.data.contentDetail;
      questionList = questionList.map(question => {
        question.isMustAnswer = question.questionCompulsory === "1";
        question.isContainAttachment = question.containAttachment === "1";
        question.questionOption = question.questionOption.map(option => {
          option.checked = option.optionType === "1";
          return option;
        });
        return question;
      });
      this.examPaperTempData = data.data;
    });
  }
  handleRevoke() {
    this.revokeExam({
      examPaperTempCode: this.examPaperTempCode
    }).then(() => {
      this.$message.success(t("v210831.the-exam-has-return"));
      this.init();
    });
  }
}
