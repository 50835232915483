
import { Component, Prop, Vue } from "vue-property-decorator";
import { isPositiveIntegerZero, isPositiveInteger } from "@/utils/verify";
import { namespace } from "vuex-class";
import t from "@common/src/i18n/t";
const base = namespace("base");
const learning = namespace("learning");
@Component({})
export default class Template extends Vue {
  @base.Action getDictionaryList;
  @learning.State questionTypeList;
  @Prop({ default: () => ({}) })
  qData: any;
  @Prop({ default: false, type: Boolean })
  readonly: boolean;
  @Prop({ default: 1 })
  index: number;
  @Prop({ default: false, type: Boolean })
  autoAddTwoRow: boolean;
  definitionQuestionData: any = {
    questionAnswerMinSize: "",
    questionIndex: "1",
    questionOption: [],
    questionScore: "",
    questionTitle: "",
    questionType: "",
    containAttachment: "0", // 必须上传影像
    questionCompulsory: "1", // 必答题
    isMustAnswer: true,
    isContainAttachment: false
  };
  defineOption: any = {
    checked: false,
    optionNo: "",
    optionType: "0",
    optionValue: "",
    placeholder: ""
  };
  questionFormRules: any = {
    questionScore: [
      { required: true, message: t("v210831.please-enter-the-now"), trigger: "blur" },
      { validator: this.checkScore, trigger: "blur" }
    ],
    questionAnswerMinSize: [
      { required: true, message: t("v210831.please-enter-the-min"), trigger: "blur" },
      { validator: this.checkAnswerMinSize, trigger: "blur" }
    ]
  };
  checkScore(rule, value, callback) {
    if (!isPositiveIntegerZero(value)) {
      callback(new Error(t("v210831.please-enter-a-0")));
    } else {
      callback();
    }
  }
  checkAnswerMinSize(rule, value, callback) {
    if (!isPositiveInteger(value)) {
      callback(new Error(t("v210831.please-enter-a-integer")));
    } else {
      callback();
    }
  }
  created() {
    if (this.autoAddTwoRow) {
      this.qData.questionOption.push(
        Object.assign({}, this.defineOption, {
          placeholder: t("v210831.click-edit-option-1")
        })
      );
      this.qData.questionOption.push(
        Object.assign({}, this.defineOption, {
          placeholder: t("v210831.click-edit-option-2")
        })
      );
    }
  }
  handleAddOption() {
    this.qData.questionOption.push(Object.assign({}, this.defineOption));
  }
  handleAnswerCheck(checked, option) {
    option.optionType = checked ? "1" : "0";
  }
  handleRemoveOption(index) {
    this.qData.questionOption.splice(index, 1);
  }
  handleQuestionMustAnswer(isMustAnswer, questionData) {
    questionData.questionCompulsory = isMustAnswer ? "1" : "0";
  }
  handleMustUploadFile(isMustUpload, questionData) {
    questionData.containAttachment = isMustUpload ? "1" : "0";
  }
  handleQuestionScoreChange() {
    this.$emit("scoreChange");
  }
  handleOrderUp(list, i) {
    if (i < 1) return;
    let temp = list[i];
    list[i] = list[i - 1];
    list.splice(i - 1, 1, temp);
  }
  handleOrderDown(list, i) {
    if (i >= list.length - 1) return;
    let temp = list[i];
    list[i] = list[i + 1];
    list.splice(i + 1, 1, temp);
  }
  handleQuestionTypeChange(type, index) {
    this.$emit("questionTypeChange", type, index);
  }
}
